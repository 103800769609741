.theme--dark .note {
    background: rgba(255,255,255,.1);
}

.theme--dark .note div p span, .theme--dark .note div p p button, .theme--dark .note div p {
    color: rgba(255,255,255,.77);
}

.theme--dark .comments-count span {
    color: #000 !important;
}