a {
    word-break: break-word;
    color: #989500;
    text-decoration: none;
    padding-left: 0.1em;
}

a:hover {
    text-decoration: underline;
}

.guide-info {
    align-items: center;
    font-size: 13px !important;
    width: 100% !important;
    display: flex;
    justify-content: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.guide-info div {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.guide-search, .guide-sort {
    margin-top: 1em !important;
    width: 100%;
    display: flex;
    justify-content: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

input {
    border-radius: 3px;
    padding-left: 10px !important;
}

.theme--dark {
    color: rgba(255, 255, 255, .77);
}

.theme--dark input {
    border: 1px solid rgba(255, 255, 255, .3);
    color: #fff;
}

.theme--dark .guide-sort div {
    color: rgba(255, 255, 255, .77);
}

.list-container li:nth-child(2) {
    justify-content: center !important;
    background: #fff;
}

.theme--dark .list-container li:nth-child(2) {
    background: #000;
}

.theme--dark .list-container {
    background: url('https://uselessshit.co/images/bitcoin-world.png');
    background-size: 100%;
    background-position-y: 10px;
}